@import url('https://fonts.googleapis.com/css?family=Titillium+Web:400,600,700');
//@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,600');

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;


$title-font: 			'Titillium Web';
$body-font:				'Titillium Web';


// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #1566b0;
$brand-secondary:		#47ba52;
$body-bg:				#eaf4f1;
$body-color:			#2b2318;

$body-bg-dark:			#102324;

$text-color:			#0a1f21;
$link-underline:		#d1bdc7;
$link-light:			#a47b8f;

$light-on-green: 		#bffdc5;
$light-on-blue:			#b4dafd;
$light-on-dark:			#7ba8aa;

$overlay:				rgba(215,215,172,0.1);


$logo: 					"../images/kinepro-logo.svg";
$logo-png: 				"../images/kinepro-logo.png";
$headerimage: 			"../images/headerimg.svg";
$headerimage-png: 		"../images/kinepro-headerimg.png";
$imageframe: 			"../images/imageframe.svg";
$imageframe-png: 		"../images/imageframe.png";
