.headerimage{
	position: relative;
	.slogan{
		position: absolute;
		left: 0;
		top: 0;
		font-size: 1.2em;
		line-height: 1.1;
		font-weight: bold;
		color: #fff;
		padding-bottom: 50px;
		padding-left: 15px;
		padding-right: 65px; 
		padding-top: 15px;
		background: url($headerimage-png) no-repeat center;
        background: url($headerimage) no-repeat center, linear-gradient(transparent, transparent);
		background-size: cover;

		@media (min-width: 768px) {

			font-size: 2em;
			padding: 35px 105px 80px 30px;
		}

		@media (min-width: 1200px) {

			font-size: 3em;
			line-height: 1;
			padding: 45px 145px 125px 35px;
		}

	}
}

.mt{
	margin-top: 25px;
}

.card{
	border: none;
	height: 100%;
	.desc{
		padding: 20px;
		h2{
			color: $brand-primary;
		}
		&.about{
			padding-top: 20px;
		}
		p:last-child, ul:last-child{
			margin-bottom: 0;
		}
		small{
			text-transform: uppercase;
			background-color: $brand-secondary;
			color: #fff;
			padding: 0 5px;
			font-weight: bold;
			font-size: 70%;
			padding-bottom: 1px;
    		vertical-align: 1px;
		}
	}
	.imageframe{
		position: relative;
		mask: url($imageframe) no-repeat left bottom;
		mask-size: cover;
		-webkit-mask: url($imageframe) no-repeat left bottom;
		-webkit-mask-size: cover;
		&:after{
			/*content: ' ';
			position: absolute;
			bottom: -1px;
			left: 0;
			width: 100%;
			height: 50px;
			//background: url($imageframe-png) no-repeat center;
			//background: url($imageframe) no-repeat center, linear-gradient(transparent, transparent);
			background-size: 115%;*/
		}	
	}
	&.garantie{
		background-color: $brand-secondary;
		color: $light-on-green;

		background: #309479; /* Old browsers */
		background: -moz-linear-gradient(45deg, #309479 0%, #47ba52 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(45deg, #309479 0%,#47ba52 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(45deg, #309479 0%,#47ba52 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#309479', endColorstr='#47ba52',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */


		h2, ul{
			color: #fff;
		}
		ul{
			list-style: none;
			margin-left: -27px;
			li {
			  text-indent: -.7em;
			}

			li::before {
			  content: "• ";
			  font-family: sans-serif;
			  vertical-align: -1px;
			  color: $light-on-green; /* or whatever color you prefer */
			}
		}
		.desc{
			padding-top: 20px;
			@media (max-width: 768px) { padding: 20px 35px;}
		}
		.img{
			height: 100%;
			background-size: cover;
			background-position: 30%;
			margin-left: -15px;
			@media (min-width: 1200px) { background-position: center;}
			img{
				visibility: hidden;
			}
		}
	}
}

.img{border-radius: 5px;}

.subpages{
	.card{
		background-color: $brand-primary;
		color: $light-on-blue;
		h2{
			color: #fff;
			a{
				color: #fff;
			}
			small{
				font-size: 0.5em;
				vertical-align: 2px;
			}
		}
	}
}

.content{
	.agenda{
		background: $brand-primary;
		color: #fff;
		padding: 20px;
		border-radius: 5px;
		margin-bottom: 25px;
		.table{
			margin-bottom: 0;
			table-layout: fixed;
			tr{
				padding: 10px;
			}
			td{
				border-top: 1px solid $light-on-blue;
				@media (min-width: 520px) { padding: 0.5rem;}
			}
			th{
				border-bottom: 1px solid $light-on-blue;
				border-top: none;
			}
			.bg-success{
				color: $brand-secondary;
				background-color: $brand-secondary;
			}
		}
		.table td:first-child, .table th:first-child{
			border: none;
			width: 105px;
		}
	}
}

.subnav{
	margin-bottom: 25px;
	background: #309479; /* Old browsers */
	background: -moz-linear-gradient(45deg, #309479 0%, #47ba52 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(45deg, #309479 0%,#47ba52 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg, #309479 0%,#47ba52 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#309479', endColorstr='#47ba52',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	border-radius: 5px;
	border: none;
	a{
		background: transparent;
		border: none;
		color: #fff;
		border-left: 1px solid transparent;
		border-right: 1px solid rgba(255,255,255,0.5);
		white-space: pre-wrap;
		display: inline-flex;
    	align-items: center;
		@media (min-width: 1200px) { 
			padding: .75rem 1.5rem;
	    	font-size: 1.25rem;
	    	border-radius: .3rem;
		}
		&:last-child{
			border-right: none;
		}
		&:first-child{
			border-left: none;
		}
		&:hover{
			background-color: $brand-primary;
			color: #fff;
			border-color: rgba(255,255,255,0.5);
		}
	}
}

.nav-tabs{
	background-color: $brand-secondary;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	margin-left: 0;
	.nav-link{
		border: none;
		border-radius: 0;
		color: $light-on-green;
		font-size: 1em;
		padding: 20px;
		background-color: transparent;
		border-left: 1px solid rgba(255,255,255,0.3);
		font-weight: bold;
		&.active{
			background-color: #fff;
			font-weight: bold;
			border: none;
			&:hover{
				color: $text-color;
			}
		}
		&:hover{
			border-color: rgba(255,255,255,0.3);
			color: #fff;
		}
	}
}
.nav-tabs>:first-child{
	.nav-link{ border-left: none;}
	
}
.tab-content{
	background-color: #fff;
	padding: 25px;
	margin-bottom: 25px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	ul{
		margin-bottom: 0;
	}
}

#galerij, #stappenplan{
	margin: 25px 0;
}

#stappenplan{
	background: #309479; /* Old browsers */
	background: -moz-linear-gradient(45deg, #309479 0%, #47ba52 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(45deg, #309479 0%,#47ba52 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg, #309479 0%,#47ba52 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#309479', endColorstr='#47ba52',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

	padding: 25px;
	border-radius: 5px;
	.imageframe{
		position: relative;
		mask: url($imageframe) no-repeat left bottom;
		mask-size: cover;
		-webkit-mask: url($imageframe) no-repeat left bottom;
		-webkit-mask-size: cover;	
	}
	p{
		color: $light-on-green;
		margin-bottom: 0;
	}
	strong{
		color: #fff;
	}
}






.wellness{
	background-color: $body-bg-dark;
	color: $light-on-dark;
	.upper-footer{
		color: #fff;
		a{
			color: #fff;
		}
	}
	#breadcrumbs{
		color: #fff;
	}

	.headerimage{
		margin-bottom: 25px;
		.slogan{
			padding: 15px 65px 35px 15px;
			@media (min-width: 768px) { padding: 35px 105px 55px 30px; }
			@media (min-width: 1200px) { padding: 45px 145px 95px 35px; }
		}
	}
	h2, h1{
		margin: 25px 0;
		color: $brand-secondary;
	}
	.badge{
		background-color: $light-on-dark;
		font-size: 0.5em;
		vertical-align: 5px;
	}
	.price-table{
		background-color: #fff;
		color: $text-color;
		border-radius: 5px;
		padding: 15px;
		margin-bottom: 25px;
		table{
			margin-bottom: 0;
		}
		th{
			border: none;
		}
		small{
			color: #4e5d5e;
		}
		td:last-child{
			text-align: right;
		}
	}
	.caldera-grid fieldset{
		border: 1px solid $light-on-dark;
		background-color: transparent;
		legend{
			color: $light-on-dark;
		}
	}
}

.maps{
	border-radius: 5px;
	box-shadow: 1px 1px 1px rgba(0,0,0,0.2);
	margin-bottom: -5px;
}