// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}



.caldera-forms-summary-label{
	font-weight: bold;
	color: $brand-primary;
}
.caldera-grid label{
	font-size: 1.1em;
}
#CF5a8ea8902f729_1-row-2{
	.last_col{
		background: $brand-primary;
		color: #fff;
		border-radius: 5px;
		padding: 10px 15px;
		padding-top: 15px;
		margin-bottom: 15px;
		p{
			margin-bottom: 5px;
		}
		h2{
			color: #fff;
		}
	}
}

.caldera-grid .checkbox input[type=checkbox], .caldera-grid .checkbox-inline input[type=checkbox], .caldera-grid .radio input[type=radio], .caldera-grid .radio-inline input[type=radio]{
	margin-right: 5px;
}
.caldera-grid{
	.intl-tel-input{
		display: block;
	}
}

.caldera-grid .alert-success{
		background-color: $brand-secondary;
		color: #fff;
		border: none;
		padding: 25px;
		text-shadow: none;
		p{
			margin-bottom: 0;
		}
}

.wellness
.caldera-grid, 
#verhuurformulier 
.caldera-grid {
	
	fieldset{
		margin-bottom: 1.2em;
	    padding: 1em;
	    border-radius: 5px;
	    background-color: #fff;
	    padding-bottom: 0.5em;
	    .row{
	    	margin: 0;
	    
	    }
	    legend{
			display: block;
			font-size: 1.5rem;
		    width: inherit;
		    padding: 0.3em;
		    margin-bottom: -15px;
		    padding-bottom: 0;
		    position: relative;
		    bottom: 0.2em;
		    border: none;
		}
	}
	.btn-default{/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1566b0+0,2d8e83+100 */
		font-weight: bold;
		background: rgb(21,102,176); /* Old browsers */
		background: -moz-linear-gradient(45deg, rgba(21,102,176,1) 0%, rgba(45,142,131,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(45deg, rgba(21,102,176,1) 0%,rgba(45,142,131,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(45deg, rgba(21,102,176,1) 0%,rgba(45,142,131,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1566b0', endColorstr='#2d8e83',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	}
		
}






