.upper-footer{
	margin-top: 35px;
	strong{
		color: $brand-primary;
	}
	.tel{
		a{
			color: $text-color;
		}
		&:before{
			content: '';
			display: inline-block;
			width: 50px;
			height: 50px;
			background: url('../images/telephone.png') no-repeat;
			float: left;
		}		
	}
}

footer{
	background-color: $brand-secondary;
	color: $light-on-green;
	margin-top: 15px;
	font-size: 0.8em;
	padding: 20px 0; 
	.nav{
		display: inline-block;
		margin-bottom: 0;
		a{
			color: #fff;
			padding-right: 0;
			background-color: transparent;
		}
		.active{
			a{color: $light-on-green;}
		}
	}

	.madeby,.copy{
		//font-size: 0.8em;
		margin-bottom: 0;
	}
	.madeby{
		padding: 10px 0px;
	}
	a{
		color: #fff;
	}
}
