html{
	background-color: #body-bg;
}
body{
	font-family: $body-font;
	font-size: 16px;
	color: $body-color;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background: $body-bg url(../images/watermerk.svg) top right no-repeat;
}

h1{
	margin-bottom: 20px;
	color: $brand-primary;
}
h1, h2{
	font-family: $title-font;
	font-weight: bold;
	color: $brand-primary;
	em{
		font-weight: normal;
	}
}

h3{
	color: $brand-primary;
	font-family: $title-font;
	font-weight: bold;
}


p{
	line-height: 1.5;
}

h2{
	font-size: 1.5em;
}

img{
	max-width: 100%;
}
.wrap{

}

ul{
	margin-left: -17px;
}



::selection {
  background: $brand-secondary; color: #fff; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: $brand-secondary; color: #fff; /* Gecko Browsers */
}


@media (max-width: 768px){
	

}

@media (min-width: 768px) and (max-width: 1400px){
	
}
@media (min-width: 1200px){
	
}
@media (min-width: 1430px){
	
}
