header{ 
    .navbar-toggler {
        background-color: $brand-primary;
        position: absolute;
        right: 15px;
        top: 26px;
        border: transparent;
        padding: 9px;
        border-radius: 3px;
        .navbar-toggler-icon {
          display: block;
          width: 22px;
          background-color: #fff;
          height: 3px;
          margin-bottom: 4px;
          border-radius: 2px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

    .brand{
        color: $brand-primary;
        text-transform: initial;
        font-weight: bold;
        display: inline-block;
        background: url($logo-png) no-repeat center;
        background: url($logo) no-repeat center, linear-gradient(transparent, transparent);
        height: 100px;
        width: 210px;
        background-size: contain;
        margin: 15px;
        span{
            position: absolute;
            left: -999999px;
        }
        &:hover{
            text-decoration: none;
        }
    }
}
.headerinfo{
    background-color: $brand-primary;
    color: $body-bg;
    font-weight: 600;
    text-align: center;
    padding: 10px;
    p{
        margin-bottom: 0;
    }
    a{
        font-size: 1.1em;
        color: #fff;
    }
}

.nav{
    @media (max-width: 768px) { display: block; margin-bottom: 15px; }
    li{
        
        a{
            font-size: 1.5em;
            background-color: $brand-primary;
            color: #fff;
            font-family: $title-font;
            padding: 10px 15px;
            display: block;
            font-weight: 600;
            @media (min-width: 768px) { background-color: transparent; color: $brand-primary; }
            &:hover, &:focus{
                //background-color: transparent
            }
        }
        &.active{
            a{
                @media (max-width: 768px) { background-color: $brand-secondary; color: #fff; }
                color: $brand-secondary;
            }   
        }
    }
}

.navbar{
    width: 100%;
    border: none;
    padding: 0;
}



@media (min-width: 768px) {
    header{
        .brand{
            
        }
        .navbar, .navbar.open-nav{
            background: transparent;
        }
    }
    #menu-main{
        margin-top: 25px;
        margin-right: 25px;
        li{
            a{
                padding-bottom: 15px;
                &:hover{
                   
                }
            }
            &:last-child{
                a{
                    margin-right: 0 !important;
                }
            }
        }
    }

}

@media (min-width: 992px) {

    #main-nav{
        li{
            a{
                
            }
            
            &.active{
                
            }
           
        }
    }



}

@media (min-width: 1200px) {


}
